import "../../assets/styles/global.css"
import "material-icons/iconfont/material-icons.css"
import "flag-icons/css/flag-icons.min.css"

import { setupLogs } from "./logs"
import { setupHeartbeats } from "./heartbeats"
import { setupTipTap } from "./tiptap"
import { setupStrings } from "./strings"

import * as Turbo from "@hotwired/turbo"
import { setupImageToggler } from "./image-toggler"

Turbo.start()

const initializeFlashNotifications = () => {
  setTimeout(() => {
    console.log("initializeFlashNotifications")
    document.querySelectorAll(".flash-notification-wrapper").forEach((e) => {
      setTimeout(() => (e.style.opacity = 1), 200)
      e.querySelector(".close-flash-notification").addEventListener(
        "click",
        () => {
          e.style.opacity = 0
          setTimeout(() => (e.style.display = "none"), 1000)
        },
      )
      setTimeout(() => {
        e.style.opacity = 0
        setTimeout(() => (e.style.display = "none"), 1000)
      }, 6000)
    })
  }, 200)
}

const hideAllFlashNotifications = () => {
  document.querySelectorAll(".flash-notification-wrapper").forEach((e) => {
    e.style.opacity = 0
    e.style.display = "none"
  })
}

const setup = async () => {
  await setupHeartbeats()
  await setupTipTap()
  await setupLogs()
  await setupStrings()
  await setupImageToggler()
}

document.addEventListener("DOMContentLoaded", () => {
  initializeFlashNotifications()
})
document.addEventListener("turbo:load", () => {
  initializeFlashNotifications()
  setup()
})
document.addEventListener("turbo:submit-end", () => {
  initializeFlashNotifications()
  setupTipTap()
})
window.addEventListener("beforeunload", function (e) {
  hideAllFlashNotifications()
})
